import { connect } from 'react-redux';
import Component from './component';

import {
    update,
    create,
    setEnabledStatus,
} from 'Redux/modules/admin/productCategories/actions';

export default connect(
    state => ({}),
    dispatch => ({
        actions: {
            update: dispatch(update),
            create: dispatch(create),
            setEnabledStatus: dispatch(setEnabledStatus),
        },
    }),
)(Component);